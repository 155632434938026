import { compile } from 'path-to-regexp'

export const routes = {
  home: '/home',
  homeWithParams: '/:chainName/home',
  token: '/:chainName/token/:tokenAddress',
  tokens: '/:chainName/tokens',
  pair: '/:chainName/pair/:pairAddress',
  pairs: '/:chainName/pairs',
  account: '/:chainName/account/:accountAddress',
  accounts: '/:chainName/accounts',
}

type Routes = {
  home: () => string
  homeWithParams: (params: { chainName: string }) => string
  token: (params: { chainName: string; tokenAddress: string }) => string
  tokens: (params: { chainName: string }) => string
  pairs: (params: { chainName: string }) => string
  account: (params: { chainName: string; accountAddress: string }) => string
  accounts: (params: { chainName: string }) => string
  pair: (params: { chainName: string; pairAddress: string }) => string
}

export const compiledRoutes = {} as Routes

for (const [section, routesList] of Object.entries(routes)) {
  compiledRoutes[section] = compile(routesList)
}
