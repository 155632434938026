import React, { FC, HTMLAttributes } from 'react'

import { Center } from './AlignerCenter'
import { Left } from './AlignerLeft'
import { Right } from './AlignerRight'
import { StyledAligner } from './styled'

export type AlignerProps = {
  valign?: 'center' | 'top' | 'baseline' | 'bottom'
  wrap?: boolean
} & HTMLAttributes<HTMLDivElement>

const AlignerComponent: FC<AlignerProps> = ({ valign = 'center', wrap, children, ...rest }) => {
  return (
    <StyledAligner
      // https://github.com/styled-components/styled-components/issues/1198
      // @ts-ignore
      wrap={wrap ? 1 : 0}
      valign={valign}
      {...rest}
    >
      {children}
    </StyledAligner>
  )
}

export const Aligner = Object.assign(AlignerComponent, {
  Left,
  Right,
  Center,
})
