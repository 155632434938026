import { ChainId } from '@elkdex/sdk'
import { CHAIN_INFO } from '../constants'

export enum ExplorerDataType {
  TRANSACTION = 'transaction',
  TOKEN = 'token',
  ADDRESS = 'address',
  BLOCK = 'block',
}

/**
 * Return the explorer link for the given data and data type
 * @param chainId the ID of the chain for which to return the data
 * @param data the data to return a link for
 * @param type the type of the data
 */
export function getExplorerLink(chainId = ChainId.AVALANCHE, data: string, type: ExplorerDataType): string {
  const { explorer } = CHAIN_INFO[chainId] || {}

  switch (type) {
    case 'transaction': {
      return `${explorer}/tx/${data}`
    }
    case 'token': {
      return `${explorer}/token/${data}`
    }
    case 'block': {
      if (chainId === ChainId.OPTIMISM) {
        return `${explorer}/tx/${data}`
      }
      return `${explorer}/block/${data}`
    }
    case 'address':
    default: {
      return `${explorer}/address/${data}`
    }
  }
}
