import { mapChainIdToTokensChainName } from '../sdk'
import { ChainId } from '@elkdex/sdk'

export const parseChainNameFromUrl = () => {
  const path = window.location.pathname.split('/')
  return path?.[1]
}

export const isValidChainName = (chainName: string) => {
  return Object.values(mapChainIdToTokensChainName).includes(chainName)
}

export const replaceChainNameForUrl = (pathname: string, newChainId: ChainId) => {
  let newPathname = pathname
  const newChainName = mapChainIdToTokensChainName[newChainId]
  Object.values(mapChainIdToTokensChainName).forEach((chainName) => {
    if (chainName !== newChainName) {
      newPathname = newPathname.replace(chainName, newChainName)
    }
  })
  return newPathname
}
