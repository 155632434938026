export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  THREE_MONTHS: '3 months',
  YEAR: '1 year',
  ALL_TIME: 'All time',
}

// hide from overview list
export const OVERVIEW_TOKEN_BLACKLIST = [
  //'0xa47a05ed74f80fa31621612887d26df40bcf0ca9',
  //'0x97b99b4009041e948337ebca7e6ae52f9f6e633c',
]

// pair blacklist
export const PAIR_BLACKLIST = []

/**
 * For tokens that cause erros on fee calculations
 */
export const FEE_WARNING_TOKENS = [
  //'0xd46ba6d942050d489dbd938a2c909a5d5039a161'
]

export const DEX_URL = 'https://app.elk.finance'

export * from './chains'
