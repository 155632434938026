import { CHAIN_ID, CHAIN_INFO } from 'constants/chains'
import React, { useState } from 'react'
import { TYPE } from '../../theme'

import { Icon } from '../Icon'
import { Spacer } from '../Spacer'
import { Text } from '../Text'
import { NetworkSelectorModal } from './NetworkSelectorModal'
import { Logo, StyledIcon, StyledNetworkSelector } from './styled'

const NetworkInfo = ({ logoSrc, title }: { logoSrc?: string; title?: string }) => {
  return (
    <>
      <Logo src={logoSrc} />
      <Spacer marginRight={8} className="hidden-xs" />
      <TYPE.TextDefault color="text1" className="hidden-xs">{title}</TYPE.TextDefault>
    </>
  )
}

export const NetworkSelector = () => {
  const chainId = CHAIN_ID

  const [isExpanded, setIsExpanded] = useState(false)

  const info = chainId ? CHAIN_INFO[chainId] : undefined

  if (!chainId || !info) {
    return null
  }

  return (
    <>
      <StyledNetworkSelector
        variant="transparent"
        onClick={() => {
          setIsExpanded(true)
        }}
      >
        <NetworkInfo logoSrc={info.iconCircle} title={info.label} />
        <Spacer marginRight={8} />
        <StyledIcon as={Text} isExpanded={isExpanded} color="text1">
          <Icon name="ChevronDown" size={10} />
        </StyledIcon>
      </StyledNetworkSelector>

      <NetworkSelectorModal
        onChainSelect={() => setIsExpanded(false)}
        isOpen={isExpanded}
        onDismiss={() => setIsExpanded(false)}
      />
    </>
  )
}
