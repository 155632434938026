import React from 'react'

import { StyledCenter } from './styled'

export class Center extends React.Component {
  render() {
    // @ts-ignore
    const { children } = this.props

    return <StyledCenter>{children}</StyledCenter>
  }
}
