import React, { useEffect } from 'react'
import 'feather-icons'

import TopTokenList from '../components/TokenList'
import { TYPE } from '../theme'
import Panel from '../components/Panel'
import { useAllTokenData } from '../contexts/TokenData'
import { PageWrapper, FullWrapper } from '../components'
import { RowBetween } from '../components/Row'
import Search from '../components/Search'
import { useMedia } from 'react-use'
import { withRouter } from 'react-router-dom'
import { mapChainNameToChainId } from '../sdk'
import { NetworkSelector } from '../components/NetworkSelector'
import { Aligner } from '../components/Aligner'
import { Spacer } from '../components/Spacer'
import { PageMeta } from '../components/PageMeta'

function AllTokensPage({
  match: {
    params: { chainName },
  },
}) {
  const chainId = mapChainNameToChainId(chainName)

  const allTokens = useAllTokenData()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const below600 = useMedia('(max-width: 800px)')

  return (
    <PageWrapper>
      <PageMeta chainId={chainId} pageId="tokens" />
      <FullWrapper>
        <RowBetween>
          <Aligner>
            <TYPE.largeHeader>Top Tokens</TYPE.largeHeader>
            <Spacer marginRight={12} />
            <NetworkSelector />
          </Aligner>
          {!below600 && <Search small={true} chainId={chainId} />}
        </RowBetween>
        <Panel style={{ marginTop: '6px', padding: below600 && '1rem 0 0 0 ' }}>
          <TopTokenList tokens={allTokens} itemMax={50} />
        </Panel>
      </FullWrapper>
    </PageWrapper>
  )
}

export default withRouter(AllTokensPage)
