import React, { useEffect } from 'react'
import 'feather-icons'

import { TYPE } from '../theme'
import Panel from '../components/Panel'
import { useAllPairData } from '../contexts/PairData'
import PairList from '../components/PairList'
import { PageWrapper, FullWrapper } from '../components'
import { RowBetween } from '../components/Row'
import Search from '../components/Search'
import { useMedia } from 'react-use'
import { withRouter } from 'react-router-dom'
import { mapChainNameToChainId } from '../sdk'
import { NetworkSelector } from '../components/NetworkSelector'
import { Spacer } from '../components/Spacer'
import { Aligner } from '../components/Aligner'
import { PageMeta } from '../components/PageMeta'

function AllPairsPage({
  match: {
    params: { chainName },
  },
}) {
  const chainId = mapChainNameToChainId(chainName)
  const allPairs = useAllPairData()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const below800 = useMedia('(max-width: 800px)')

  return (
    <PageWrapper>
      <PageMeta chainId={chainId} pageId="pairs" />
      <FullWrapper>
        <RowBetween>
          <Aligner>
            <TYPE.largeHeader>Top Pairs</TYPE.largeHeader>
            <Spacer marginRight={12} />
            <NetworkSelector />
          </Aligner>
          {!below800 && <Search small={true} chainId={chainId} />}
        </RowBetween>
        <Panel style={{ padding: below800 && '1rem 0 0 0 ' }}>
          <PairList pairs={allPairs} disbaleLinks={true} maxItems={50} />
        </Panel>
      </FullWrapper>
    </PageWrapper>
  )
}

export default withRouter(AllPairsPage)
