import { darken } from 'polished'
import styled, { css } from 'styled-components/macro'

import { Text } from '../Text'
import { ButtonProps } from './Button'

export const StyledButton = styled.button<ButtonProps>`
  align-items: center;
  background: ${(props) => props.theme.primary1};
  border: none;
  border-radius: 24px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: bold;
  height: 50px;
  justify-content: center;
  max-width: 100%;
  padding: 0 20px;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
  width: 100%;
  transition: opacity 0.3s ease-in-out;
  color: ${(p) => p.theme.white};
  //padding-top: 2px; // visual vertical alignment fix

  &:hover {
    text-decoration: none;
  }

  //&:disabled {
  //  cursor: default;
  //  opacity: 0.5;
  //  pointer-events: none;
  //}

  &:focus {
    //box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1)};
    //background-color: ${({ theme }) => darken(0.05, theme.primary1)};
    opacity: 0.8;
  }
  &:hover {
    //background-color: ${({ theme }) => darken(0.05, theme.primary1)};
    opacity: 0.7;
  }
  &:active {
    //box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.primary1)};
    opacity: 0.9;
  }
  &:disabled {
    //background-color: ${({ theme }) => theme.primary1};
    opacity: 0.2;
    cursor: auto;
    box-shadow: none;
    outline: none;
  }

  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.2;
      cursor: auto;
      box-shadow: none;
      outline: none;
      pointer-events: none;
    `}

  ${(props) =>
    props.size === 'xxs' &&
    css`
      font-size: 12px;
      height: 22px;
      border-radius: 5px;
      padding-left: 8px;
      padding-right: 8px;
    `}

  ${(props) =>
    props.size === 'xs' &&
    css`
      font-size: 12px;
      height: 24px;
      border-radius: 24px;
      padding-left: 20px;
      padding-right: 20px;
      font-weight: 400;
    `}

  ${(props) =>
    props.size === 'sm' &&
    css`
      font-size: 12px;
      height: 30px;
    `}

  ${(props) =>
    props.size === 'md' &&
    css`
      font-size: 14px;
      height: 40px;
    `}

  ${(props) =>
    props.variant === 'transparent' &&
    css`
      height: auto;
      border-radius: 0;
      background-color: transparent;
      color: inherit;
      padding: 0;
      font-weight: normal;
      width: auto;

      &:hover {
        opacity: 0.7;
      }

      &:hover,
      &:active,
      &:focus,
      &:disabled {
        background-color: transparent;
      }
    `}
  
  ${(p) =>
    p.variant === 'asLink' &&
    css`
      height: auto;
      border-radius: 0;
      background-color: transparent;
      color: ${(p) => p.theme.primary1};
      padding: 0;
      font-weight: normal;
      display: inline-flex;
      width: auto;

      &:hover {
        opacity: 0.7;

        ${StyledText} {
          text-decoration: none;
        }
      }

      &:hover,
      &:active,
      &:focus,
      &:disabled {
        background-color: transparent;
      }

      ${StyledText} {
        font-size: 12px;
        text-decoration: underline;
      }
    `}

  ${(p) =>
    p.variant === 'greenDark' &&
    css`
      background-color: ${(p) => p.theme.secondary4};
    `}

  ${(p) =>
    p.variant === 'purple' &&
    css`
      background-color: ${(p) => p.theme.secondary2};
    `}

  ${(p) =>
    p.variant === 'red' &&
    css`
      background-color: ${(p) => p.theme.red};
    `}
  
  ${(p) =>
    p.variant === 'whiteAndRed' &&
    css`
      background-color: ${(p) => p.theme.white};
      color: ${(p) => p.theme.red};
    `}

  ${(p) =>
    p.inline &&
    css`
      width: fit-content;
      text-align: left;
    `}
`

export const StyledText = styled(Text)`
  display: flex;
  align-items: center;
`
