import React, { FC, useMemo } from 'react'
import { Helmet } from 'react-helmet'

import { createMeta, defaultTitle } from '../../metaTags'
import { routes } from '../../routes'
import { CHAIN_INFO } from '../../constants'
import { ChainId } from '@elkdex/sdk'

export type PageMetaProps = {
  chainId: ChainId
  pageId: keyof typeof routes
}

export const PageMeta: FC<PageMetaProps> = ({ chainId, pageId }) => {
  const { label } = CHAIN_INFO[chainId] || {}
  const { title, description } = useMemo(() => {
    const meta = createMeta(label)
    return meta[pageId]
  }, [label, pageId])

  return (
    <Helmet titleTemplate="Elk Finance | %s" defaultTitle={defaultTitle}>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}

      <meta property="twitter:url" content={window.location.href} />
      {title && <meta name="twitter:title" content={title} data-react-helmet="true" />}
      {description && <meta name="twitter:description" content={description} />}

      <meta property="og:url" content={window.location.href} />
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
    </Helmet>
  )
}
