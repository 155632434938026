import React, { useEffect } from 'react'
import 'feather-icons'
import { withRouter } from 'react-router-dom'
import { TYPE } from '../theme'
import { PageWrapper, FullWrapper } from '../components'
import Panel from '../components/Panel'
import LPList from '../components/LPList'
import styled from 'styled-components'
import AccountSearch from '../components/AccountSearch'
import { useTopLps } from '../contexts/GlobalData'
import LocalLoader from '../components/LocalLoader'
import { RowBetween } from '../components/Row'
import { useMedia } from 'react-use'
import Search from '../components/Search'
import { mapChainNameToChainId } from '../sdk'
import { NetworkSelector } from '../components/NetworkSelector'
import { Aligner } from '../components/Aligner'
import { Spacer } from '../components/Spacer'
import { PageMeta } from '../components/PageMeta'

const AccountWrapper = styled.div`
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

function AccountLookup({
  match: {
    params: { chainName },
  },
}) {
  const chainId = mapChainNameToChainId(chainName)

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const topLps = useTopLps()

  const below600 = useMedia('(max-width: 600px)')

  return (
    <PageWrapper>
      <PageMeta chainId={chainId} pageId="accounts" />
      <FullWrapper>
        <RowBetween>
          <Aligner>
            <TYPE.largeHeader>Wallet analytics</TYPE.largeHeader>
            <Spacer marginRight={16} />
            <NetworkSelector />
          </Aligner>
          {!below600 && <Search small={true} chainId={chainId} />}
        </RowBetween>
        <AccountWrapper>
          <AccountSearch />
        </AccountWrapper>
        <TYPE.main fontSize={'1.125rem'} style={{ marginTop: '2rem' }}>
          Top Liquidity Positions
        </TYPE.main>
        <Panel>{topLps && topLps.length > 0 ? <LPList lps={topLps} maxItems={200} /> : <LocalLoader />}</Panel>
      </FullWrapper>
    </PageWrapper>
  )
}

export default withRouter(AccountLookup)
