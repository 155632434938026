import { ChainId } from '@elkdex/sdk'

import { CHAIN_ID_SELECTED_KEY } from 'constants/chains'
import { mapChainIdToTokensChainName } from '../sdk'
import { replaceChainNameForUrl } from './routing'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const switchChain = (chainId: ChainId) => {
  const chainName = mapChainIdToTokensChainName[chainId]
  localStorage?.setItem(CHAIN_ID_SELECTED_KEY, chainName)

  window.location.href = replaceChainNameForUrl(window.location.pathname, chainId)
}
