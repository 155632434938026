import React, { HTMLAttributes } from 'react'

import { Colors } from '../../theme/styled'
// eslint-disable-next-line no-restricted-imports
import * as Svg from './dist/index'
import { StyledIcon } from './styled'

export type IconProps = {
  name: keyof typeof Svg | string
  size?: 10 | 12 | 14 | 16 | 20 | 24 | 32 | 40 | 72 | 200 | 'inherit'
  color?: keyof Colors
} & HTMLAttributes<HTMLDivElement>

export const Icon = ({ name, size = 24, color, ...rest }: IconProps) => {
  // @ts-ignore
  const IconComponent = Svg[name]

  return (
    <StyledIcon name={name} size={size} color={color} {...rest}>
      {IconComponent && <IconComponent />}
    </StyledIcon>
  )
}
