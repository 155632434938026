import { useEffect } from 'react'
import { CHAIN_ID_SELECTED_KEY, CHAIN_NAME } from '../constants'
import { compiledRoutes } from '../routes'
import { isValidChainName, parseChainNameFromUrl } from '../utils/routing'

export default function FetchChainIdFromUrl(): null {
  useFetchChainIdFromUrl()
  return null
}

function useFetchChainIdFromUrl() {
  useEffect(() => {
    const parsedChainId = parseChainNameFromUrl()
    const isChainId = isValidChainName(parsedChainId)
    if (!isChainId) {
      localStorage?.setItem(CHAIN_ID_SELECTED_KEY, CHAIN_NAME)
      window.location.pathname = compiledRoutes.homeWithParams({ chainName: CHAIN_NAME })
    } else if (CHAIN_NAME !== parsedChainId && isChainId) {
      localStorage?.setItem(CHAIN_ID_SELECTED_KEY, parsedChainId)
      window.location.reload()
    }
  }, [])
}
