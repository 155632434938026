import React from 'react'
import { Modal, ModalProps } from 'components/Modal'
import { TYPE } from '../../../theme'

import { Spacer } from '../../Spacer'
import { ChainsList } from '../ChainsList'
import { RowProps } from '../Row'
import { StyledCustomDialogContent, StyledNetworkSelectorContainer } from './styled'

export const NetworkSelectorModal = ({
  onChainSelect,
  ...rest
}: ModalProps & { onChainSelect: RowProps['onChainSelect'] }) => {
  return (
    <Modal components={{ DialogContent: StyledCustomDialogContent }} {...rest}>
      <TYPE.Header>Select network</TYPE.Header>
      <Spacer margin={24} />
      <StyledNetworkSelectorContainer>
        <ChainsList onChainSelect={onChainSelect} />
      </StyledNetworkSelectorContainer>
    </Modal>
  )
}
