import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Text as UIText, TextProps as TextPropsOriginal } from 'rebass'
import { Colors } from '../../theme/styled'

export type TextProps = Omit<TextPropsOriginal, 'css'> & { caps?: boolean; color?: keyof Colors }

export type TextWrapperProps = TextProps

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TextWrapper = styled(({ color, ...rest }) => <UIText {...rest} />)<TextWrapperProps>`
  color: ${({ color = 'text1', theme }) => theme[color] || color};

  ${(p) =>
    // @ts-ignore
    p.caps &&
    css`
      text-transform: uppercase;
    `}
`

export const Text = ({ ...rest }: TextWrapperProps) => {
  return <TextWrapper {...rest} />
}
