import { DialogContent, DialogOverlay } from '@reach/dialog'
import React from 'react'
import { animated } from 'react-spring'
import styled, { css } from 'styled-components/macro'

import { mq } from '../../theme/mediaQueries'

const AnimatedDialogOverlay = animated(DialogOverlay)
export const StyledDialogOverlay = styled(AnimatedDialogOverlay)`
  &[data-reach-dialog-overlay] {
    //z-index: 10;
    display: flex;
    background-color: ${({ theme }) => theme.shadow2};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: 1; // safari fix
  }
`

export const StyledDialogWrapper = styled.div`
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
  margin: auto;

  ${mq.xs} {
    padding: 0;
    margin: auto auto 0;
  }
`

const modalContainerStyles = css`
  background: ${({ theme }) => theme.modalBG};
  box-shadow: 0px 0px 40px rgba(1, 15, 30, 0.25);
  border-radius: 16px;
  padding: 20px;

  ${mq.xs} {
    border-radius: 16px 16px 0 0;
  }
`

export const ModalContainer = styled.div`
  ${modalContainerStyles}
`

const AnimatedDialogContent = animated(DialogContent)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledDialogContent = styled(({ minHeight, maxHeight, mobile, isOpen, ...rest }) => (
  <AnimatedDialogContent {...rest} />
)).attrs({
  'aria-label': 'dialog',
})`
  ${modalContainerStyles};
  max-width: 360px;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  width: 100%;

  ${mq.xs} {
    margin: 0;
    max-width: none;
  }
`

export const StyledCloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`
