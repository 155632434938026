import React, { FC, HTMLAttributes } from 'react'

import { StyledWraper } from './styled'

export type SpacerProps = {
  margin?: 0 | 4 | 8 | 12 | 16 | 20 | 24 | 32 | 40 | 48 | number
  marginRight?: 0 | 4 | 8 | 12 | 16 | 20 | 24 | number
  as?: any
} & HTMLAttributes<HTMLDivElement>

export const Spacer: FC<SpacerProps> = ({ margin = 16, marginRight = 0, children, ...rest }) => {
  return (
    <StyledWraper margin={margin} marginRight={marginRight} {...rest}>
      {children}
    </StyledWraper>
  )
}
