import styled, { css } from 'styled-components/macro'

import { IconProps } from './Icon'

export const StyledIcon = styled.span<IconProps>`
  display: inline-flex;
  vertical-align: middle;
  ${(p) =>
    p.color &&
    css`
      color: ${
        // @ts-ignore
        p.theme[p.color]
      };
    `}

  svg {
    display: inline-block;
    height: ${(props) => (props.size === 'inherit' ? '1em' : `${props.size}px`)};
    width: ${(props) => (props.size === 'inherit' ? '1em' : `${props.size}px`)};
    line-height: 1em;
    position: relative;
    vertical-align: middle;
    flex: none;
    fill: currentColor;
  }
`
