import React, { ReactNode, useEffect } from 'react'
import {
  // useSpring,
  useTransition,
} from 'react-spring'

// import { useGesture } from 'react-use-gesture'
import { isMobile } from '../../utils/userAgent'
import { Button } from '../Button'
import { Icon } from '../Icon'
// @ts-ignore
import { StyledCloseButton, StyledDialogContent, StyledDialogOverlay, StyledDialogWrapper } from './styled.tsx'

export type ModalProps = {
  isOpen: boolean
  onDismiss?: () => void
  minHeight?: number | false
  maxHeight?: number
  initialFocusRef?: React.RefObject<any>
  children?: React.ReactNode
  // onCloseClick?: () => void
  components?: {
    DialogContent?: ReactNode
  }
}

const defaultComponents = {
  DialogContent: StyledDialogContent,
}

export function Modal({
  isOpen,
  onDismiss,
  minHeight = false,
  maxHeight = 90,
  initialFocusRef,
  children,
  components,
}: // onCloseClick,
ModalProps) {
  const { DialogContent } = { ...defaultComponents, ...components }

  useEffect(() => {
    const root = document.getElementById('root')
    if (isOpen) {
      root && root.classList.add('isBlurred')
    } else {
      root && root.classList.remove('isBlurred')
    }

    return () => {
      root && root.classList.remove('isBlurred')
    }
  }, [isOpen])

  const fadeTransition = useTransition(isOpen, null, {
    config: { duration: 200 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  // const [{ y }, set] = useSpring(() => ({ y: 0, config: { mass: 1, tension: 210, friction: 20 } }))
  // const bind = useGesture({
  //   onDrag: (state) => {
  //     set({
  //       y: state.down ? state.movement[1] : 0,
  //     })
  //     if (state.movement[1] > 300 || (state.velocity > 3 && state.direction[1] > 0)) {
  //       onDismiss?.()
  //     }
  //   },
  // })

  return (
    <>
      {fadeTransition.map(
        ({ item, key, props }) =>
          item && (
            <StyledDialogOverlay
              key={key}
              style={props}
              onDismiss={onDismiss}
              initialFocusRef={initialFocusRef}
              unstable_lockFocusAcrossFrames={false}
            >
              <StyledDialogWrapper>
                <DialogContent
                  {...(isMobile
                    ? {
                        // ...bind(),
                        // style: { transform: y.interpolate((y) => `translateY(${(y as number) > 0 ? y : 0}px)`) },
                      }
                    : {})}
                  aria-label="dialog content"
                  minHeight={minHeight}
                  maxHeight={maxHeight}
                  mobile={isMobile}
                >
                  {/* prevents the automatic focusing of inputs on mobile by the reach dialog */}
                  {!initialFocusRef && isMobile ? <div style={{ outline: 'none' }} tabIndex={1} /> : null}
                  {children}
                  {onDismiss && (
                    <StyledCloseButton>
                      <Button variant="transparent" onClick={onDismiss}>
                        <Icon name="Cross" />
                      </Button>
                    </StyledCloseButton>
                  )}
                </DialogContent>
              </StyledDialogWrapper>
            </StyledDialogOverlay>
          )
      )}
    </>
  )
}

export default Modal
