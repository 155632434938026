import styled, { css } from 'styled-components/macro'

import { AlignerProps as Props } from './Aligner'

const valignDict = { center: 'center', top: 'flex-start', baseline: 'baseline', bottom: 'flex-end' }

export const StyledAligner = styled.div<Props>`
  display: flex;
  align-items: ${(props) => props.valign && valignDict[props.valign]};

  ${(props) =>
    props.wrap &&
    css`
      flex-wrap: wrap;
    `};
`

export const StyledLeft = styled.div`
  flex: auto;
  min-width: 0;
`

export const StyledRight = styled.div`
  margin-left: auto;
  padding-left: 16px;
  flex: 0 0 auto;
  max-width: 100%;
  box-sizing: border-box;
`

export const StyledCenter = styled.div`
  margin-left: auto;
  margin-right: auto;
`
