import { routes } from './routes'

export const defaultTitle = `Elk Finance | Cross-chain DEX Analytics`
export const defaultDescription = `Cross-chain ElkDEX Analytics for supported chains. Track your decentralized swaps, liquidity pooling, LP farming, and single-staking farming`

export const createMeta = (
  chainName?: string
): Record<
  keyof typeof routes,
  {
    title?: string
    description?: string
    twitterDescription?: string
    twitterTitle?: string
    ogTitle?: string
    ogDescription?: string
  }
> => {
  return {
    home: {
      title: `Elk Finance | Cross-chain ${chainName} DEX Analytics`,
      description: `Cross-chain ElkDEX Analytics for ${chainName}. Track your decentralized swaps, liquidity pooling, LP farming, and single-staking farming`,
    },
    homeWithParams: {},
    token: {},
    tokens: {
      title: `Elk Finance | Cross-chain ${chainName} DEX Tokens`,
      description: `Cross-chain ElkDEX Analytics for ${chainName}. Track your decentralized swaps, liquidity pooling, LP farming, and single-staking farming`,
    },
    pair: {},
    pairs: {
      title: `Elk Finance | Cross-chain ${chainName} DEX Pairs`,
      description: `Cross-chain ElkDEX Analytics for ${chainName}. Track your decentralized swaps, liquidity pooling, LP farming, and single-staking farming`,
    },
    account: {},
    accounts: {},
  }
}
