import styled from 'styled-components/macro'

import { mq } from '../../../theme/mediaQueries'
import { StyledDialogContent } from '../../Modal/styled'

export const StyledCustomDialogContent = styled(StyledDialogContent)`
  max-width: 720px;
`
export const StyledNetworkSelectorContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 32px;

  ${mq.xs} {
    display: block;
  }
`
