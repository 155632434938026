import React, { useState } from 'react'
import styled from 'styled-components'
import { ApolloProvider } from 'react-apollo'
import { Route, Switch, Redirect, BrowserRouter, withRouter } from 'react-router-dom'
import GlobalPage from './pages/GlobalPage'
import TokenPage from './pages/TokenPage'
import PairPage from './pages/PairPage'
import { useGlobalData, useGlobalChartData } from './contexts/GlobalData'
import { isAddress } from './utils'
import AccountPage from './pages/AccountPage'
import AllTokensPage from './pages/AllTokensPage'
import AllPairsPage from './pages/AllPairsPage'
import PinnedData from './components/PinnedData'

import SideNav from './components/SideNav'
import AccountLookup from './pages/AccountLookup'
import { CHAIN_ID_SELECTED_KEY, CHAIN_NAME, OVERVIEW_TOKEN_BLACKLIST, PAIR_BLACKLIST } from './constants'
import LocalLoader from './components/LocalLoader'
import GoogleAnalyticsReporter from './components/analytics/GoogleAnalyticsReporter'
import { useLatestBlocks } from './contexts/Application'
import { compiledRoutes, routes } from './routes'
import { Updater as LocalStorageContextUpdater } from './contexts/LocalStorage'
import { Updater as PairDataContextUpdater } from './contexts/PairData'
import { Updater as TokenDataContextUpdater } from './contexts/TokenData'
import FetchChainIdFromUrl from './hooks/useFetchChainIdFromUrl'
import { client } from './apollo/client'
import { mapChainNameToChainId } from './sdk'
import { isValidChainName, parseChainNameFromUrl } from './utils/routing'

const AppWrapper = styled.div`
  position: relative;
  width: 100%;
`
const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ open }) => (open ? '220px 1fr 200px' : '220px 1fr 64px')};

  @media screen and (max-width: 1400px) {
    grid-template-columns: 220px 1fr;
  }

  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr;
    max-width: 100vw;
    overflow: hidden;
    grid-gap: 0;
  }
`

const Right = styled.div`
  position: fixed;
  right: 0;
  bottom: 0rem;
  z-index: 99;
  width: ${({ open }) => (open ? '220px' : '64px')};
  height: ${({ open }) => (open ? 'fit-content' : '64px')};
  overflow: auto;
  background-color: ${({ theme }) => theme.bg1};
  @media screen and (max-width: 1400px) {
    display: none;
  }
`

const Center = styled.div`
  height: 100%;
  z-index: 9999;
  transition: width 0.25s ease;
  background-color: ${({ theme }) => theme.onlyLight};
`

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const WarningBanner = styled.div`
  background-color: #ff6871;
  padding: 1.5rem;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: 500;
`

/**
 * Wrap the component with the header and sidebar pinned tab
 */
const LayoutWrapperPure = ({
  match: {
    params: { chainName },
  },
  children,
}) => {
  const chainId = mapChainNameToChainId(chainName)
  const [savedOpen, setSavedOpen] = useState(false)

  const globalData = useGlobalData(chainId)
  useGlobalChartData(chainId)
  const [latestBlock, headBlock] = useLatestBlocks()
  const isGlobalDataLoaded = (globalData && Object.keys(globalData).length > 0) || globalData?.error
  // show warning
  const showWarning = headBlock && latestBlock ? headBlock - latestBlock > BLOCK_DIFFERENCE_THRESHOLD : false
  return (
    <ApolloProvider client={client}>
      <AppWrapper>
        {showWarning && (
          <WarningWrapper>
            <WarningBanner>
              {`Warning: The data on this site has only synced to block ${latestBlock} (out of ${headBlock}). Please check back soon.`}
            </WarningBanner>
          </WarningWrapper>
        )}
        {latestBlock && isGlobalDataLoaded ? (
          <>
            <Updaters chainId={chainId} />
            <ContentWrapper open={savedOpen}>
              <SideNav />
              <Center id="center">{children}</Center>
              <Right open={savedOpen}>
                <PinnedData open={savedOpen} setSavedOpen={setSavedOpen} />
              </Right>
            </ContentWrapper>
          </>
        ) : (
          <LocalLoader fill="true" />
        )}
      </AppWrapper>
    </ApolloProvider>
  )
}
const LayoutWrapper = withRouter(LayoutWrapperPure)

const BLOCK_DIFFERENCE_THRESHOLD = 30

function Updaters({ chainId }) {
  return (
    <>
      <LocalStorageContextUpdater />
      <PairDataContextUpdater chainId={chainId} />
      <TokenDataContextUpdater chainId={chainId} />
    </>
  )
}

function RedirectToHome() {
  const parsedChainId = parseChainNameFromUrl()
  const isChainId = isValidChainName(parsedChainId)
  if (isChainId && parsedChainId !== CHAIN_NAME) {
    /* eslint-disable no-unused-expressions */
    localStorage?.setItem(CHAIN_ID_SELECTED_KEY, parsedChainId)
    window.location.pathname = compiledRoutes.homeWithParams({ chainName: parsedChainId })
    return null
  }

  return <Redirect to={compiledRoutes.homeWithParams({ chainName: CHAIN_NAME })} />
}

const App = () => (
  <BrowserRouter>
    <Route component={FetchChainIdFromUrl} />
    <Route component={GoogleAnalyticsReporter} />
    <Switch>
      <Route
        exacts
        strict
        path={routes.token}
        render={({ match }) => {
          if (OVERVIEW_TOKEN_BLACKLIST.includes(match.params.tokenAddress.toLowerCase())) {
            return <Redirect to={routes.home} />
          }
          if (isAddress(match.params.tokenAddress.toLowerCase())) {
            return (
              <LayoutWrapper>
                <TokenPage address={match.params.tokenAddress.toLowerCase()} />
              </LayoutWrapper>
            )
          } else {
            return <Redirect to={routes.home} />
          }
        }}
      />
      <Route
        exacts
        strict
        path={routes.pair}
        render={({ match }) => {
          if (PAIR_BLACKLIST.includes(match.params.pairAddress.toLowerCase())) {
            return <Redirect to={routes.home} />
          }
          if (isAddress(match.params.pairAddress.toLowerCase())) {
            return (
              <LayoutWrapper>
                <PairPage pairAddress={match.params.pairAddress.toLowerCase()} />
              </LayoutWrapper>
            )
          } else {
            return <Redirect to={routes.home} />
          }
        }}
      />
      <Route
        exacts
        strict
        path={routes.account}
        render={({ match }) => {
          if (isAddress(match.params.accountAddress.toLowerCase())) {
            return (
              <LayoutWrapper>
                <AccountPage account={match.params.accountAddress.toLowerCase()} />
              </LayoutWrapper>
            )
          } else {
            return <Redirect to={routes.home} />
          }
        }}
      />
      <Route path={routes.homeWithParams}>
        <LayoutWrapper>
          <GlobalPage />
        </LayoutWrapper>
      </Route>

      <Route path={routes.tokens}>
        <LayoutWrapper>
          <AllTokensPage />
        </LayoutWrapper>
      </Route>

      <Route path={routes.pairs}>
        <LayoutWrapper>
          <AllPairsPage />
        </LayoutWrapper>
      </Route>

      <Route path={routes.accounts}>
        <LayoutWrapper>
          <AccountLookup />
        </LayoutWrapper>
      </Route>
      <Route component={RedirectToHome} />
    </Switch>
  </BrowserRouter>
)

export default App
