import React from 'react'
import { AnchorHTMLAttributes, ElementType, FC, HTMLAttributes } from 'react'
import { TextProps } from 'rebass'

import { StyledButton, StyledText } from './styled'

export type ButtonProps = {
  size?: 'default' | 'xxs' | 'xs' | 'sm' | 'md'
  variant?: 'default' | 'transparent' | 'asLink' | 'greenDark' | 'purple' | 'red' | 'whiteAndRed'
  inline?: boolean
  disabled?: boolean
  textProps?: TextProps
  href?: string
  as?: ElementType // fix for styled components
} & (HTMLAttributes<HTMLButtonElement> | AnchorHTMLAttributes<HTMLAnchorElement>)

export const Button: FC<ButtonProps> = ({ children, textProps, variant, href, ...rest }) => {
  return (
    <StyledButton href={href} as={href ? 'a' : 'button'} variant={variant} {...rest}>
      <StyledText color="inherit" {...textProps}>
        {children}
      </StyledText>
    </StyledButton>
  )
}
