import React from 'react'
import { Spacer } from 'components/Spacer'
import { CHAIN_ID, CHAIN_INFO } from 'constants/chains'
import { useCallback } from 'react'
import { switchChain } from 'utils/switchChain'

import { FlyoutRow, FlyoutRowActiveIndicator, Logo, NetworkLabel } from '../styled'

export type RowProps = { disabled?: boolean; targetChain: number; onChainSelect?: () => void }

export const Row = ({ disabled = false, targetChain, onChainSelect }: RowProps) => {
  const chainId = CHAIN_ID

  const handleRowClick = useCallback(async () => {
    if (disabled) return
    try {
      onChainSelect?.()
      await switchChain(targetChain)
    } catch (e) {
      console.error('switchChain', e)
    }
  }, [disabled, onChainSelect, targetChain])

  const active = chainId === targetChain
  // const isOptimism = targetChain === ChainId.OPTIMISM
  const rowText = `${CHAIN_INFO[targetChain]?.label}`
  const RowContent = () => (
    <FlyoutRow isActive={active} onClick={handleRowClick} isDisabled={disabled}>
      <Logo isActive={active} src={CHAIN_INFO[targetChain].iconCircle} />
      <Spacer marginRight={8} margin={8} />
      <NetworkLabel>{rowText}</NetworkLabel>
      {active && <FlyoutRowActiveIndicator />}
    </FlyoutRow>
  )

  if (!chainId) {
    return null
  }

  return <RowContent />
}
